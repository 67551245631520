.button {
    display: inline-flex;
	@if ( get-font-family(heading) != get-font-family(base) ) {
		@include font-family(heading);
	}
    @include font-size(7, mobile, true, false, true);
    @if ( get-font-size(7, desktop) != get-font-size(7, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(7, desktop, true, false, true);
        }
    }
    @include font-weight(bold);
    line-height: 16px;
    text-decoration: none !important;
    background-color: color(bg, 1);
	background: color(bg, 1);
    color: color(primary, 1) !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	height: 48px;
    text-align: center;
    white-space: nowrap;

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2px;
    }
}

.button-shadow {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 8px 16px rgba(color(typography, 1), .12);
        mix-blend-mode: multiply;
        transition: box-shadow .15s ease;
    }

    &:hover {

        &::before {
            box-shadow: 0 8px 16px rgba(color(typography, 1), .16);
        }
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;

    &.button-shadow {

        &::before {
            box-shadow: 0 4px 16px rgba(color(typography, 1), .12);
        }

        &:hover {

            &::before {
                box-shadow: 0 4px 16px rgba(color(typography, 1), .16);
            }
        }
    }
}

.button-primary,
.button-secondary {
    color: color(typography, 1i) !important;
    transition: background .15s ease;
}

.button-primary {
    background: color(primary, 1);

    &:hover {
        background: lighten(color(primary, 1), 3%);
    }

    &.button-shadow {

        &::before {
            box-shadow: 0 8px 16px rgba(color(primary, 1), .24);
        }

        &:hover {

            &::before {
                box-shadow: 0 8px 16px rgba(color(primary, 1), .32);
            }
        }
    }

    .button-sm {

        &.button-shadow {

            &::before {
                box-shadow: 0 4px 16px rgba(color(primary, 1), .24);
            }

            &:hover {

                &::before {
                    box-shadow: 0 4px 16px rgba(color(primary, 1), .32);
                }
            }
        }
    }
}

.button-secondary {
	background: mix(color(secondary, 1), color(quaternary, 3));
    background: linear-gradient(65deg, color(secondary, 1) 0, color(quaternary, 3) 100%);

    &:hover {
		background: lighten(mix(color(secondary, 1), color(quaternary, 3)), 3%);
        background: linear-gradient(65deg, lighten(color(secondary, 1), 1%) 0, lighten(color(quaternary, 3), 3%) 100%);
    }

    &.button-shadow {

        &::before {
            box-shadow: 0 8px 16px rgba(mix(color(secondary, 1), color(quaternary, 3)), .24);
        }

        &:hover {

            &::before {
                box-shadow: 0 8px 16px rgba(mix(color(secondary, 1), color(quaternary, 3)), .32);
            }
        }
    }

    .button-sm {

        &.button-shadow {

            &::before {
                box-shadow: 0 4px 16px rgba(mix(color(secondary, 1), color(quaternary, 3)), .24);
            }

            &:hover {

                &::before {
                    box-shadow: 0 4px 16px rgba(mix(color(secondary, 1), color(quaternary, 3)), .32);
                }
            }
        }
    }
}

.button-block {
    display: flex;
	width: 100%;
}
