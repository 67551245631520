.hero {
    position: relative;
	padding-top: 88px;
	text-align: center;
	z-index: 0;
    padding-bottom: 140px;
    margin-bottom: 20px;

	.hero-left-decoration,
	.hero-right-decoration {
		position: absolute;
		background-repeat: no-repeat;
	}

	.hero-left-decoration {
		display: none;
		width: 1440px;
		height: 342px;
		bottom: 0px;
        left: 20px;
		background-image: url('../images/header-bg-left.svg');
        background-size: contain;
		z-index: -2;
	}

	.hero-right-decoration {
		width: 720px;
		height: 320px;
        right: 20px;
		top: -100px; /* min -80px, i.e. header height */
		background-image: url('../images/header-bg-right.svg');
        background-size: contain;
        background-position: right;
		z-index: -1;
	}
}

.hero-copy,
.hero-illustration {
	position: relative; /* to display inner elements above the illustration */
}

.hero-copy {
	padding-bottom: 48px;
}

.hero-paragraph {
    margin-bottom: 32px;
}

@include media( '>medium' ) {

    .hero {
		text-align: left;
        padding-top: 100px;

		.hero-left-decoration {
			display: block;
		}

		.hero-right-decoration {
			width: 1440px;
			height: 620px;
			top: 0; /* min -80px, i.e. header height */
		}
    }

	.hero-inner {
		/* Split hero in two parts */
		display: flex;
	}

	.hero-copy {
		padding-right: 48px;
		min-width: 512px;
		width: 512px;
	}
}

@include media( '<xlarge' ) {
    .hero .hero-right-decoration,
    .hero .hero-left-decoration {
        width: 570px;
    }
}


@include media( '<large' ) {
    .hero .hero-right-decoration,
    .hero .hero-left-decoration {
        width: 540px;
    }
}

@include media( '<mlarge' ) {
    .hero .hero-right-decoration {
        display: none;
    }

    .hero {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .hero .hero-left-decoration {
        background-position: center center;
        width: 100%;
        margin: 0 auto;
        position: static;
        margin-bottom: 20px;
    }

    .hero {
        text-align: center;
    }

    .hero .hero-inner {

        .hero-copy {
            width: 100%;
        }
    }
}

@include media( '<=medium' ) {

	.hero-cta {

		.button {
			width: 100%;
			max-width: 280px;
		}
	}
}

@include media('<medium') {
    .hero .hero-right-decoration {
        display: block;
        margin-top: 30px;
        right: 40px;
    }

    .hero .hero-inner {
        padding-top: 250px;
    }
}
