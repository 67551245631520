.site-header {
    position: relative;
    padding: 24px 0;
}

.header-sun {
	position: absolute;
	top: -88px;
	right: -60px;
}

.brand a {
    display: block;
    height: 42px;
    width: auto;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.site-header-inner {
    position: relative; /* To display all elements above the background color */
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media( '<mlarge' ) {
        .brand {
            width: 100%;
            text-align: right;

            a {
                display: inline-block;
            }
        }
    }
}

.header-links {
    display: inline-flex;
    z-index: 1;

    li {
        display: inline-flex;
    }

    a:not(.button) {
        @include font-size(7, mobile, true, true, true);
        @if ( get-font-size(7, desktop) != get-font-size(7, mobile) ) {
            @include media( '>medium' ) {
                @include font-size(7, desktop, true, true, true);
            }
        }
        @include font-weight(bold);
        @include anchor-aspect(header);
        line-height: 16px;
        padding: 8px 24px;
    }
}

@include media( '>medium' ) {

	.header-sun {
		left: 440px;
		right: auto;
	}
}

@include media( '>large' ) {

	.header-sun {
		left: auto;
		right: 43%;
	}
}
