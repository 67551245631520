.cta {
	position: relative;
	text-align: center;

	.section-inner {
		padding-bottom: 64px;
	}

	.section-title {
		margin-bottom: 32px;
	}

	&::before {
		content: '';
		position: absolute;
		right: 20px;
		bottom: -120px;
		width: 1440px;
		height: 374px;

		background-image: url('../images/cowork.svg');
        background-size: 400px;
        background-position: right bottom;
		background-repeat: no-repeat;
	}
}

.cta-inner {
	/* Make CTA's widht match pricing tables */
	max-width: 664px;
	margin: 0 auto;
}

@include media( '>medium') {

	.cta {
		text-align: left;

		.section-inner {
			padding-top: 102px;
			padding-bottom: 102px;
		}

		.section-title {
			margin-bottom: 0;
			padding-right: 24px;
		}
	}

	.cta-inner {
		//display: flex;
		//align-items: center;
		//justify-content: space-between;
	}
}

@include media( '<=medium' ) {

	.cta-cta {

		.button {
			width: 100%;
			max-width: 280px;
		}
	}
}
