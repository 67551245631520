.is-boxed {
    background: color(bg, 2);
}

.body-wrap {
    background: color(bg, 1);
    overflow: hidden;
    /* Sticky footer */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.boxed-container {
    max-width: 1440px;
    margin: 0 auto;
    @include shadow;
}

main {
    flex: 1 0 auto;
}

.section-inner {
    position: relative; /* To always display inner elements above pseudo decorative stuff */
    padding-top: 48px;
    padding-bottom: 48px;
}

@include media( '>medium' ) {

    .section-inner {
        padding-top: 88px;
        padding-bottom: 88px;
    }
}

.form-input {
    width: 100%;
    margin-bottom: 20px;
    padding: .5rem .75rem;
    //border: 1px solid color('typography', 4);
    border: none;
    box-shadow: 0 8px 16px rgba(color(typography, 1), .12);

    &:focus {
        outline: none;
        box-shadow: 0 8px 16px rgba(color(primary, 1), .24);
    }
}

textarea.form-input {
    min-height: 160px;
}

@include media('<medium') {
    form {
        margin-bottom: 100px;
    }
}


.cta-inner h3 {
    padding-bottom: 15px;
}
