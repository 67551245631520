.clients {

    .section-inner {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 12px;
        width: 174px;
        height: 74px;
    }

    a{
        display: block;
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        opacity: 0.3;

        &:hover {
            filter: grayscale(0%);
            opacity: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@include media( '<medium' ) {
    .clients {
        ul {
            justify-content: space-around;
        }
    }
    .clients ul li {
        width: 50%;
    }
}

@include media( '>medium' ) {

    .clients {

        ul {
            justify-content: space-around;
        }

        li {
            //width: auto;
        }
    }
}
