.accordion {
	@include font-size(7, mobile, true, true, true);
    list-style: none;
    padding: 0;

    li {

		&::before,
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: color(bg, 3);
        }

		+ li {

			&::before {
				content: normal;
			}
		}
    }

    p {
        margin-top: 0;
    }
}

.accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    cursor: pointer;
	color: color(typography, 1);
	@include font-weight(bold);

    span {
        width: calc(100% - 32px);
    }
}

.accordion-body {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height .15s ease-in-out,opacity .15s;

    .is-open & {
        opacity: 1;
    }

    p:last-child {
        margin-bottom: 24px;
    }
}

.accordion-icon {
    position: relative;
    width: 16px;
    height: 16px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: color(primary, 1);
        transition: transform .25s ease-out;
    }

    &::before {
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        margin-left: -1px;
    }

    &::after {
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-top: -1px;
    }

    .is-open & {
        cursor: pointer;

        &::before {
            transform: rotate(90deg);
        }

        &::after {
            transform: rotate(180deg);
        }
    }
}

@include media( '>medium' ) {

	.accordion {
		@include font-size(6, desktop, true, true, true);
	}
}
